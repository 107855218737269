.container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 9px 5px;
  background-color: white;
  z-index: -1;
  border-bottom: 1px solid var(--color-grey-light);
}

.container .icon {
  background: url(/assets-new/shop-trigger.svg) 50% no-repeat;
  background-size: 24px;
  height: 24px;
  text-align: center;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 10px 0px 5px;
}

.container .icon span {
  font-family: var(--font-moderne);
  display: inline-block;
  font-size: var(--description-size);
  text-align: center;
  vertical-align: -webkit-baseline-middle;
  color: var(--color-black);
  transform: translateY(4px);
}

.container .text {
  font-family: var(--font-moderne);
  text-decoration: underline;
}

.container .close {
  height: 100%;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-items: center;
  cursor: pointer;
}
